export const disableField = [
  {
    label: '禁用备注',
    prop: 'disableReason',
    name: 'reason',
    colNum: 16,
    placeholder: '请输入禁用备注',
    rule: []
  }
]
